/**
 * api接口统一管理
 */

import { get, post, put, upload, download } from '@/utils/request_z'

// const HttpType = { get, post, put, upload, download }

// export default HttpType

// 表格数据
export const tableList = (apiUrl, params) => new Promise((resolve, reject) => get(apiUrl, params).then(res => {
  const { list, page } = res
  let config = { total: 0, pageCount: 0, list: [] }
  if (list) {
    config = { total: page.total, pageCount: page.last_page, list }
  }
  resolve(config)
}).catch(err => reject(err)))


/**
 * @typedef {Object} BaseInterface
 * @property {'video_article_basic' | 'video_article_config' | 'video_knowledge_basic' | 'video_category'} zh_history_type 类型名称
 * @property {{label:string, value:string|number}} option 字符串数组
 */

const fw = '/voluntary'
const zh = '/wisdom-education'
const zs = '/zhaosheng/api'
const api1 = '/api1'
const api2 = '/api2/api'

const apiMap = { fw, zh, zs, api1, api2 }

const http = (custom = {}, data = {}, config = {}) => new Promise((resolve, reject) => {
  const { url, method, pre } = custom
  if (!url) return reject('url不能为空')
  const types = ['get', 'post', 'put']
  if (!types.includes(method)) return reject('请求类型错误')
  const prefixMap = Object.keys(apiMap)
  if (pre && !prefixMap.includes(pre)) return reject('请求前缀错误')
  const prefix = pre ? apiMap[pre] : ''
  if (method === 'get') {
    return get(`${prefix}${url}`, data, config).then(res => resolve(res)).catch(err => reject(err))
  } else if (method === 'post') {
    return post(`${prefix}${url}`, data, config).then(res => resolve(res)).catch(err => reject(err))
  } else if (method === 'put') {
    return put(`${prefix}${url}`, data, config).then(res => resolve(res)).catch(err => reject(err))
  }
})
const changeCustom = (custom, data = {}, config = {}, type) => {
  let url = '', pre = '';
  let newType = type || 'get'
  if (typeof custom === 'string') {
    url = custom
  } else {
    url = custom.url
    if (!type && custom.method) newType = custom.method
    if (custom.pre) pre = custom.pre
  }
  return http({ method: newType, url, pre }, data, config)
}
/**
 * @function 设置统一api接口方法
 * @param {object|string} custom 自定义参数 string/object
 * @param {string} custom.url 请求地址data 请求参数
 * @param {'get'|'post'|'put'} custom.method 请求类型
 * @param {string} custom.pre 请求前缀
 * @param {object} data 请求参数
 * @param {object} config 配置参数
 * @returns
 */

const baseApi = (custom, data = {}, config = {}) => changeCustom(custom, data, config)
/**
 * @function api.get
 * @param {object|string} custom 自定义参数
 * @param {object} data 请求参数
 * @param {object} config 配置参数
 * @returns
 */
baseApi.get = (custom, data = {}, config = {}) =>  changeCustom(custom, data, config, 'get')
/**
 * @function api.post
 * @param {object|string} custom 自定义参数
 * @param {object} data 请求参数
 * @param {object} config 配置参数
 * @returns
 */
baseApi.post = (custom, data = {}, config = {}) => changeCustom(custom, data, config, 'post')
/**
 * @function api.put
 * @param {object|string} custom 自定义参数
 * @param {object} data 请求参数
 * @param {object} config 配置参数
 * @returns
 */
baseApi.put = (custom, data = {}, config = {}) => changeCustom(custom, data, config, 'put')

export default baseApi
export { baseApi }
/**
 * 设置统一智慧教育接口方法
 */
/**
 * @function 智慧教育/wisdom-education
 * @param {string} api 请求地址
 * @param {object} params 请求参数
 * @param {object} config 配置参数
 * @returns
 */
export const zhGet = (api, params = {}, config = {}) => get(`${zh}${api}`, params, config)
/**
 * @function 智慧教育/wisdom-education
 * @param {string} api 请求地址
 * @param {object} data 更改参数
 * @param {object} config 配置参数
 * @returns
 */
export const zhPut = (api, data = {}, config = {}) => put(`${zh}${api}`, data, config)
/**
 * @function 智慧教育/wisdom-education
 * @param {string} api 请求地址
 * @param {object} data 保存参数
 * @param {object} config 配置参数
 * @returns
 */
export const zhPost = (api, data = {}, config = {}) => post(`${zh}${api}`, data, config)

/**
 * 设置统一招生接口方法
 */
/**
 * @function 招生/zhaosheng/api
 * @param {string} api 请求地址
 * @param {object} params 请求参数
 * @param {object} config 配置参数
 * @returns
 */
export const zsGet = (api, data = {}, config = {}) => get(`${zs}${api}`, data, config)
/**
 * @function 招生/zhaosheng/api
 * @param {string} api 请求地址
 * @param {object} data 更改参数
 * @param {object} config 配置参数
 * @returns
 */
export const zsPut = (api, data = {}, config = {}) => put(`${zs}${api}`, data, config)
/**
 * @function 招生/zhaosheng/api
 * @param {string} api 请求地址
 * @param {object} data 保存参数
 * @param {object} config 配置参数
 * @returns
 */
export const zsPost = (api, data = {}, config = {}) => post(`${zs}${api}`, data, config)

/**
 * 设置统一api2接口方法
 */
/**
 * @function /api2/api
 * @param {string} api 请求地址
 * @param {object} params 请求参数
 * @param {object} config 配置参数
 * @returns
 */
export const getApi2 = (api, data = {}, config = {}) => get(`${api2}${api}`, data, config)
/**
 * @function /api2/api
 * @param {string} api 请求地址
 * @param {object} data 更改参数
 * @param {object} config 配置参数
 * @returns
 */
export const putApi2 = (api, data = {}, config = {}) => put(`${api2}${api}`, data, config)
/**
 * @function /api2/api
 * @param {string} api 请求地址
 * @param {object} data 保存参数
 * @param {object} config 配置参数
 * @returns
 */
export const postApi2 = (api, data = {}, config = {}) => post(`${api2}${api}`, data, config)


/**
 * @function 上传模板文件
 * @param {string} api 请求地址
 * @param {object?} data 上传参数
 * @param {object?} config 配置参数
 * @returns
 */
export const fileUpload = (api, data = {}, config = {}) => upload(api, data, config)

/**
 * @function 下载文件
 * @param {string} api 请求地址
 * @param {object?} params 请求参数
 * @param {object?} config 配置参数
 * @returns
 */
export const fileDownload = (api, params = {}, config = {}) => download(api, params, config)

/**
 * 常用筛选
 */
// 校区筛选
export const get_school = () => get(`${api2}/common/screen/school`)
// 校区筛选2
export const get_school2 = () => get(`${api1}/site/school`)
// 校区筛选3
export const get_school3 = () => get(`${api2}/question/common/schools`)
// 年级筛选
export const get_grade = () => get(`${api2}/common/screen/grade`)
// 年级筛选2
export const get_grade2 = () => get(`${api1}/site/grades`)
// 年级筛选3
export const get_grade3 = () => get(`${api2}/question/common/grades`)
// 全部年级
export const get_grade_all = () => get(`${api2}/common/screen/grade-all`)
// 招生年级
export const get_grade_zs = () => get(`${zs}/recruit/common/grade`)
// 行政班
export const get_class = data => get(`${api2}/common/screen/admin-class`, data)
// 角色类型
export const get_role = () => get(`${api2}/common/screen/role-type`)
// 专业方向/学习方向
export const get_major = () => get(`${api2}/common/screen/major`)

/**
 * 其他
 */
// 操作记录列表
export const get_history = data => get(`${api2}/common/public/get-opt-history`, data)


/**
 * 系统使用优化迭代
 * 设置 校区基础信息管理 学生账号管理
 * 教务 教务信息管理 学生管理
 * 个人信息 设置 账号设置
 * 其他 CMD
 */
// 获取token
export const system_getToken = data => post(`/api4/get_token`, data)
// 教师工作助手解邦
export const system_unbinding = data => put(`/api4/account_data/unbinding`, data)
// 重置密码
export const system_reset_pwd = data => put(`/api4/account_data/reset_pwd`, data)
// 修改账户
export const system_account_change = data => post(`${api2}/account-student/teacher/change-account`, data)
// 开发人员的mysql,redis指令工具
export const system_cmd = data => post(`${api2}/tool/dev-tool/cmd`, data)
// 操作记录列表
export const system_history = data => get(`/api4/opt_history/list`, data)


/**
 * 艺体生转入转出留痕及催缴
 * 一期 设置 校区基础信息管理 专业方向管理 选科组合管理
 */
const as_api = `${api2}/educational-routine/art/`
// 获取选科组合固定选项
export const as_combined = data => get(`${api2}/common/screen/combined`, data)
// 一期 专业方向管理
// 新增
export const as_major_add = data => post(`${as_api}major/store`, data)
// 编辑
export const as_major_edit = data => post(`${as_api}major/update`, data)
// 详情
export const as_major_show = data => get(`${as_api}major/show`, data)
// 选科组合管理
// 新增
export const as_subject_add = data => post(`${as_api}combined/store`, data)
// 编辑
export const as_subject_edit = data => post(`${as_api}combined/update`, data)
// 详情
export const as_subject_show = data => get(`${as_api}combined/show`, data)


/**
 * 艺体生转入转出留痕及催缴
 * 二期 教务 艺体生业务  财务 艺体生缴费 教务 教务信息管理 学生管理
 */
const as2_api = `${api2}/educational-routine/art`
// 列表搜索行政班
export const as2_search_class = data => get(`${as2_api}/apply/search-class`, data)
// 搜索学生
export const as2_search_student = data => get(`${as2_api}/apply/search-student`, data)
// 获取学生信息
export const as2_get_student = data => get(`${as2_api}/apply/get-student`, data)
// 获取班级下的班主任
export const as2_get_class_teacher = data => get(`${api2}/common/screen/class-teacher`, data)
// 搜索教师
export const as2_search_teacher = data => get(`${as2_api}/apply/search-teacher`, data)
// 联动科目组合
export const as2_combined_list = data => get(`${as2_api}/combined/linkage`, data)
// 获取联动专业方向
export const as2_major_list = data => get(`${as2_api}/major/linkage`, data)

// 转入转出申请详情
export const as2_apply_show = data => get(`${as2_api}/apply/show`, data)
// 检测教学班是否存在冲突
export const as2_apply_check = data => post(`${as2_api}/apply/check-teaching`, data)
// 转入转出申请新增
export const as2_apply_store = data => post(`${as2_api}/apply/store`, data)
// 转入收款进度详情
export const as2_collect_show = data => get(`${as2_api}/collect/show`, data)
// 艺体生转入缴费记录详情
export const as2_pay_show = data => get(`${as2_api}/pay/show`, data)
// 艺体生转入缴费记录编辑
export const as2_pay_update = data => post(`${as2_api}/pay/update`, data)
// 获取缴费记录
export const as2_pay_record = data => get(`${as2_api}/pay/record`, data)
// 获取操作日志记录
export const as2_pay_history = data => get(`${api2}/educational-routine/public/opt-history`, data)

// 艺体转入/转出记录
export const as2_art_record = data => get(`${api2}/student/details/art-record`, data)

/**
 * 2024招生线上报名v1.0 - 配置功能
 * 招生 设置 2024容缺人员 2024中考配置 2024针诊断性考试科目 2024诊断性考试管理 2024学校录取分数线
 */
// 2024招生 获取省市区 is_level=1 获取地址层级结构   address_type  级别，1：省级，2：市，3：区
export const get_2024area = data => zsGet('/recruit-2024/exam/get-city-code', data)
// 2024招生 获取省市区
export const get_2024government = data => zsGet('/recruit-2024/exam/get-city-code', data)
// 2024招生 学科
export const get_2024subject = () => zsGet('/recruit-v2/common/get-subject')
// 2024招生 学校
export const get_2024schools = () => zsGet('/recruit/common/schools')

// 2025招生 获取套餐类型
export const get_2025pack = () => new Promise((resolve, reject) => {
  zsGet('/recruit/account-manage/get-pack-type').then(res => {
    if (!res) return resolve([])
    const list = res.map(item => ({ label: item.name, value: item.type }))
    resolve(list)
  }).catch(err => reject(err))
})


/**
 * 2024招生线上报名v1.8
 * 招生 客户管理 应接受报名
 */
// 查询招生教师   [status] 教师状态，0：禁用，1：启用 [name] 学习方向
export const get_recruit_teacher = data => zsGet('/recruit/common/get-recruit-teacher', data)
// 应届生报名订单总数
export const get_recruit_count = data => zsGet('/recruit/fresh-graduate/count', data)
// 修改应届生报名订单当前客户归属人
export const edit_belong_teacher = data => zsPost('/recruit/fresh-graduate/chg-belong-teacher', data)


/**
 * 鸿鹄智慧教育平台 - 基础配置
 */

/**
 * 将对象转换成数组
 * @param {object} res 转换的对象
 * @param {boolean} isKey value是否是key 默认 false
 * @returns {BaseInterface['option'][]}
 */
export const changeObjToArr = (res, isKey = false) => {
  if (!res) return []
  if (typeof res !== 'object') {
    return []
  }
  if (Array.isArray(res)) {
    return res.map((item, idx) => ({ value: isKey ? idx : item, label: item }))
  }
  return Object.keys(res).map(key => {
    return { value: isKey ? Number(key) : res[key], label: res[key] }
  })
}

/**
 * @function 历史操作记录
 * @param {object} data 请求参数
 * @param {BaseInterface['zh_history_type']} data.type 类型 [video_article_basic,video_article_config,video_knowledge_basic,video_category]->[教材版本,章节管理,知识点管理,分类管理]
 * @param {number} data.id id值
 * @param {number} [data.page] 查询页数
 * @param {number} [data.page_size] 每页条数
 * @returns {Promise<*[]>}
 */
export const zh_opt_history = data => {
  const { page = 1, page_size = 20, type, id } = data
  const params = { page, page_size, tbl_name: type, tbl_id: id }
  return zhGet('/get-opt-history', params)
}

/**
 * @function 获取学段列表
 * @returns {Promise<*[]>}
 */
export const zh_grade_year = () => new Promise((resolve, reject) => {
  zhGet('/video/grade-year').then(res => {
    if (!res) return resolve([])
    const list = Object.keys(res).map(key=>{
      const { grade, id, years } = res[key]
      return { value: id, label: grade, children: changeObjToArr(years, true) }
    })
    resolve(list)
  }).catch(err => {
    reject(err)
  })
})

